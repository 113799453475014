export const fontSizes = {
  font8: '8px',
  font10: '10px',
  font12: '12px',
  font14: '14px',
  font16: '16px',
  font18: '18px',
  font20: '20px',
  font22: '22px',
  font24: '24px',
  font26: '26px',
  font28: '28px',
  font30: '30px',
  font32: '32px',
  font34: '34px',
  font36: '36px',
  font38: '38px',
  font40: '40px',
  font42: '42px',
  font44: '44px',
  font46: '46px',
};
