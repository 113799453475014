'use client';
import { fontSizes } from '@/styles/fontSizes';
import styled from 'styled-components';
import TournamentCard from '../../../components/events/TournamentCard';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { get } from 'aws-amplify/api';
import { EventType } from '@/app/types/types';

const StyledSwiper = styled(Swiper)`
  padding-bottom: 40px; /* Add padding to the bottom */
  .swiper-button-next,
  .swiper-button-prev {
    --swiper-navigation-size: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px;
  }
  .swiper-pagination-bullets {
    bottom: 0px; /* Adjust this value to move pagination dots up or down */
  }
`;

const MoreTournaments = () => {
  const [events, setEvents] = useState<EventType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const restOperation = get({
        apiName: 'api',
        path: '/events',
        options: {
          queryParams: {
            limit: '8',
          },
        },
      });

      // Await the response Promise to get the response data
      const response = await restOperation.response;
      const data: any = await response.body.json();
      const events = data.items;

      if (events) {
        setEvents((events as any[]).slice(0, 8));
      }
    } catch (error) {
      console.log('error', error);
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <MoreTournamentsWrapper>
      <StyledSwiper
        spaceBetween={10}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        pagination={{ clickable: true }}
        breakpoints={{
          0: { slidesPerView: 1, spaceBetween: 0 },
          700: { slidesPerView: 2, spaceBetween: 20 },
          1060: { slidesPerView: 3, spaceBetween: 20 },
          1420: { slidesPerView: 4, spaceBetween: 20 },
          1780: { slidesPerView: 5, spaceBetween: 20 },
          2140: { slidesPerView: 6, spaceBetween: 20 },
          2500: { slidesPerView: 7, spaceBetween: 20 },
        }}
        className="md:mt-8 px-4"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
      >
        {events.map((event: EventType) => (
          <SwiperSlide key={`mt ${event.occurrenceID}`}>
            <TournamentCard event={event} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </MoreTournamentsWrapper>
  );
};

export default MoreTournaments;

const MoreTournamentsWrapper = styled.div`
  padding: 20px;
  text-align: center;
  padding: 20px 0;
  max-width: 2500px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: ${fontSizes.font24};
  font-weight: 700;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: ${fontSizes.font14};
  margin-bottom: 20px;
`;
